import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const BannerSection = memo(({ theme, sectionId, dataTestId }) => {
  const BannerLayout = dynamic(() => import(/* webpackChunkName: 'BannerSectionLayout' */ `../../../../../containers/hub-page/views/sections/banner/layouts/${theme.layout}`))

  return (
    <BannerLayout
      sectionId={sectionId}
      data-testid={dataTestId}
    />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

BannerSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number,
  dataTestId: PropTypes.string.isRequired
}

export default BannerSection
