import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useDisclosure,
  Alert
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import Imgix from 'react-imgix'

// Required for date questions in the sign up form
import 'flatpickr/dist/flatpickr.min.css'

const isProd = process.env.NODE_ENV === 'production'

const SignUpModal = ({ linkColor }) => {
  const { user, features, site } = useSelector((state) => {
    return {
      user: state.currentUser.user,
      features: state.features.features,
      site: state.currentSite.site
    }
  })

  // If user is not signed in (role is null) && feature toggle is enabled
  const isRegistrationUpgrade = !user.roleName &&
    features['registration_upgrades'] &&
    features['registration_upgrades'].enabled

  const isPfeAuthenticated = !user.roleName &&
    features['enable_pfe_authentication'] &&
    features['enable_pfe_authentication'].enabled

  if (isPfeAuthenticated || !isRegistrationUpgrade) { return false }

  // Script load state
  const [isLoading, setIsLoading] = useState(false)
  const [doneLoading, setDoneLoading] = useState(false)
  const [loadError, setLoadError] = useState('')

  // Modal state
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loadScript = () => {
    setIsLoading(true)

    const script = document.createElement('script')
    document.body.appendChild(script)

    script.onload = () => {
      setIsLoading(false)
      setDoneLoading(true)
    }

    script.onerror = (message) => {
      setIsLoading(false)
      setDoneLoading(true)
      setLoadError(message)
    }

    // Script served by v2, source code: https://github.com/bangthetable/steroids,
    // to be updated manually whenever a new version is published
    script.src = '/SignUp.6961ba52a183b097b7dc.bundle.js'
  }

  useEffect(() => {
    const handleClick = (e) => {
      const href = e.target.getAttribute('href')

      if (href === '/register' || href === '/login') {
        e.preventDefault()

        if (!isLoading && !doneLoading) {
          loadScript()
        }

        onOpen()

        const view = href === '/register' ? 'sign_up' : 'sign_in'
        document.querySelector('#SignUpEntry').setAttribute('data-view', view)

        if (window.Steroids) {
          if (view === 'login') {
            window.Steroids.SignUp.login()
          } else {
            window.Steroids.SignUp.register()
          }
        }
      }
    }

    document.body.addEventListener('click', handleClick)

    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent className='SignUpModal'>
        <ModalHeader>
          {site.logoUrl && (
            isProd ? (
              <Imgix
                src={site.logoUrl}
                height={40}
                disableSrcSet
                imgixParams={{
                  'max-w': 240
                }}
                htmlAttributes={{
                  alt: site.name,
                  height: 40
                }}
              />
            ) : (
              <img
                src={site.logoUrl}
                alt={site.name}
                height={40}
                width={240}
              />
            )
          )}
        </ModalHeader>
        <ModalCloseButton className='ModalClose' />
        <ModalBody className='ModalBody'>
          <div id='SignUpEntry'>
            { loadError ? <Alert>{loadError}</Alert> : <Spinner /> }
          </div>
        </ModalBody>
      </ModalContent>
      {/* Style flatpickr input similar to chakra input fields */}
      <style jsx global>{`
        .SignUpModal {
          background: #fff;
        }

        .SignUpModal,
        .ModalBody,
        #SignUpEntry {
          border-radius: .25rem;
        }

        .SignUpModal .ModalBody {
          padding: 0;
          background: inherit;
        }

        #SignUpEntry img {
          width: 100%;
        }

        @media screen and (min-width: 768px) {
          .SignUpModal .ModalBody {
            padding: 0 76px;
          }
        }

        .SignUpModal .ModalClose {
          box-shadow: none;
          outline: none;
        }

        #SignUpEntry {
          padding: .5rem 1.5rem 1.5rem;
          background: inherit;
          min-height: 300px;
        }

        #SignUpEntry .modal-box h1,
        #SignUpEntry .modal-box h2,
        #SignUpEntry .modal-box h3,
        #SignUpEntry .modal-box h4,
        #SignUpEntry .modal-box h5,
        #SignUpEntry .modal-box h6,
        #SignUpEntry .modal-box p,
        #SignUpEntry .modal-box .RegistrationMessage * {
          font-family: inherit !important;
          color: #000 !important;
          margin-block-start: 1em;
          margin-block-end: 1em;
        }

        #SignUpEntry .modal-box a{
          color: ${linkColor} !important;
        }

        .flatpickr-input {
          width: 100%;
          height: 2.5rem;
          font-size: 1rem;
          padding: 0 1rem;
          border-width: 1px;
          border-radius: .25rem;
        }

        #SignUpEntry {
          min-height: 300px;
        }

        .RadioButtonQuestion,
        .CheckBoxQuestion {
          border: none;
          padding: 0;
          margin: 0;
        }

        .FormErrorMessage p {
          margin-bottom: 0;
        }

        #SignUpEntry label,
        #SignUpEntry legend {
          margin: 0;
          font-weight: 600;
          color: #000 !important;
          padding: 0 12px 10px 0;
        }

        .TosQuestion label {
          align-items: flex-start;
        }

        .TosQuestion input[type="checkbox"] + div {
          margin-top: 4px;
        }

        #SignUpEntry input::-webkit-outer-spin-button, #SignUpEntry input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        #SignUpEntry input[type=number] {
          -moz-appearance: textfield;
        }

        .ohnohoney {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          height: 0;
          width: 0;
          z-index: -1;
        }
      `}</style>
    </Modal>
  )
}

export default SignUpModal
