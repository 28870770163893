import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchProjects, resetProjectsPage, updateFilters, updateQuery } from 'actions/projects'
import ProjectsSection from 'components/hub-page/views/sections/projects'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  hub: state.hub.hub,
  dataTestId: 'participant-hubs-views-projects-section'
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    fetchProjects,
    resetProjectsPage,
    updateFilters,
    updateQuery
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSection)
