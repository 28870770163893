import { connect } from 'react-redux'

import Index from 'components/hub-page/views/sections/navigations'

const mapStateToProps = state => {
  return {
    isGoogleTranslateEnabled:
      state.siteSettings.settings.googleTranslationEnabled,
    dataTestId: 'participant-hubs-views-navigations-section',
    brandColor: state.theme.theme.brandColor
  }
}

export default connect(mapStateToProps)(Index)
