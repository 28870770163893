import { connect } from 'react-redux'

import HtmlSection from 'components/hub-page/views/sections/html'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-views-html-section'
})

export default connect(mapStateToProps)(HtmlSection)
