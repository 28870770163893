import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const VideoSection = memo(({ theme, sectionId, dataTestId }) => {
  const VideoSectionLayout = dynamic(() => import(/* webpackChunkName: 'VideoSectionLayout' */ `../../../../../containers/hub-page/views/sections/video/layouts/${theme.layout}`))

  return (
    <VideoSectionLayout sectionId={sectionId} data-testid={dataTestId} />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

VideoSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number,
  dataTestId: PropTypes.string.isRequired
}

export default VideoSection
