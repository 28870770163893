import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import isUserOnIE from 'lib/is-user-on-ie'

import SignUpModal from './sign-up-modal'

const NavigationsSection = ({
  sectionId,
  isInsideAdminView,
  isGoogleTranslateEnabled,
  dataTestId,
  brandColor
}) => {

  const Header = dynamic(() =>
    import(
      /* webpackChunkName: 'NavigationHeaderLayout' */ `../../../../../containers/hub-page/views/sections/navigations/header`
    )
  )

  const integrationsSectionNode = () => {
    if (!isGoogleTranslateEnabled || isUserOnIE()) return null

    const IntegrationsSection = dynamic(() =>
      import(
        /* webpackChunkName: 'NavigationIntegrationsLayout' */ `../../../../../containers/hub-page/views/sections/integrations`
      )
    )

    return <IntegrationsSection />
  }

  return (
    <>
      {integrationsSectionNode()}
      <Header sectionId={sectionId} isInsideAdminView={isInsideAdminView} data-testid={dataTestId} />
      <SignUpModal linkColor={brandColor} />
    </>
  )
}

NavigationsSection.propTypes = {
  sectionId: PropTypes.number,
  isInsideAdminView: PropTypes.bool,
  readspeakerLicense: PropTypes.string,
  isReadSpeakerEnabled: PropTypes.bool.isRequired,
  isGoogleTranslateEnabled: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
  brandColor: PropTypes.string.isRequired,
}

export default NavigationsSection
