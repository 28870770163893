import { connect } from 'react-redux'
import find from 'lodash/find'

import FooterSection from 'components/hub-page/views/sections/footer'
import fontFamilies from 'configs/font-families'

const mapStateToProps = (state, ownProps) => {
  const fetchedContentFont = find(fontFamilies, (f) => f.key === state.theme.theme.contentFont)
  const footerSectionIndex = state.homePageRevision.revision.sections &&
    state.homePageRevision.revision.sections.length - 1

  const textColor = footerSectionIndex && state.homePageRevision.revision.sections[footerSectionIndex].theme.textColor
  const fontWeight = footerSectionIndex && state.homePageRevision.revision.sections[footerSectionIndex].theme.fontWeight
  const fontSize = footerSectionIndex && state.homePageRevision.revision.sections[footerSectionIndex].theme.fontSize
  const layout = footerSectionIndex && state.homePageRevision.revision.sections[footerSectionIndex].theme.layout

  const footerProps = {
    textColor: textColor || state.hubPageRevision.revision.sections[ownProps.sectionId].theme.textColor,
    fontFamily: (fetchedContentFont && fetchedContentFont.name) || fontFamilies[0].name,
    fontWeight: fontWeight || state.hubPageRevision.revision.sections[ownProps.sectionId].theme.fontWeight,
    fontSize: fontSize || state.hubPageRevision.revision.sections[ownProps.sectionId].theme.fontSize,
    layout: layout || state.hubPageRevision.revision.sections[ownProps.sectionId].theme.layout,
    dataTestId: 'participant-hubs-views-footer-section'
  }

  return footerProps
}

export default connect(mapStateToProps)(FooterSection)
