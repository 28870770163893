import { connect } from 'react-redux'

import BannerSection from 'components/hub-page/views/sections/banner'

const mapStateToProps = (state, ownProps) => ({
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-views-banner-section'
})

export default connect(mapStateToProps)(BannerSection)
