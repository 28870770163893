import { connect } from 'react-redux'

import WhosListeningSection from 'components/hub-page/views/sections/whos-listening'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-views-whos-listening-section'
})

export default connect(mapStateToProps)(WhosListeningSection)
