import { connect } from 'react-redux'

import LifeCycleSection from 'components/hub-page/views/sections/life-cycle'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-lviews-ife-cycle-section'
})

export default connect(mapStateToProps)(LifeCycleSection)
