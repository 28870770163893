import { connect } from 'react-redux'

import TitleSection from 'components/hub-page/views/sections/title'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-views-title-section'
})

export default connect(mapStateToProps)(TitleSection)
