import { connect } from 'react-redux'

import CarouselSection from 'components/hub-page/views/sections/carousel'

const mapStateToProps = (state, ownProps) => ({
  meta: state.hubPageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.hubPageRevision.revision.sections[ownProps.sectionId].theme,
  dataTestId: 'participant-hubs-cviews-arousal-section'
})

export default connect(mapStateToProps)(CarouselSection)
