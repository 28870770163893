import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const KeyDatesSection = memo(({ theme, sectionId, dataTestId }) => {
  const KeyDatesLayout = dynamic(() => import(/* webpackChunkName: 'KeyDatesSectionLayout' */ `../../../../../containers/hub-page/views/sections/key-dates/layouts/${theme.layout}`))

  return (
    <KeyDatesLayout sectionId={sectionId} data-testid={dataTestId} />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

KeyDatesSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number,
  dataTestId: PropTypes.string.isRequired
}

export default KeyDatesSection
