import React, { useEffect, memo, useState } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import Query from 'lib/prepare-query'
import Project from 'api/project'

const ProjectsSection = memo(({
  meta,
  sectionId,
  theme,
  isInsideAdminView,
  tags,
  hub,
  dataTestId
}) => {
  const [projectsForThisSection, setProjectsForThisSection] = useState([])

  useEffect(() => {
    /**
     * Fetch all the projects for this section using the project filters
     * and queries. Then, iterate over the fetched projects and show those
     * projects.
     */
    const fetchProjectsForThisSection = async () => {
      const filters = {
        sort: {
          published_at: 'desc'
        },
        parent_id: hub.id,
        visibility_mode: hub.visibilityMode === 'Public' ? 'Public' : ''
      }

      const query = {
        page: 1,
        per_page: 999,
        filterable: false,
        fields: {
          'project': [
            'name',
            'image_url',
            'description',
            'permalink',
            'state',
            'published_at',
            'platform_analytics_tag_list',
            'parent_id',
            'image_description'
          ]
        }
      }

      /**
       * We can't use the Projects reducer here as there is only one projects reducer
       * but there can be more than one dynamic projects sections.
       *
       * As a result, we need to fetch the data manually and pass it to each dynamic
       * projects section.
       */

      const queryObject = new Query(query, filters)
      const response = await Project.query(queryObject)

      setProjectsForThisSection(response.map(project => ({ id: project.id, ...project.attributes() })))
    }

    fetchProjectsForThisSection()
  }, [meta])

  const ProjectFinderLayout = dynamic(() => import(/* webpackChunkName: 'ProjectFinderSectionLayout' */ `../../../../../containers/hub-page/views/sections/project-finder/layouts/${theme.layout}`))

  return (
    <ProjectFinderLayout
      sectionId={sectionId}
      projects={projectsForThisSection}
      tags={tags}
      autoAddProjects={meta.autoAddProjects}
      isInsideAdminView={isInsideAdminView}
      data-testid={dataTestId}
    />
  )
}, (prevProps, nextProps) => {
  if (prevProps.isInsideAdminView) {
    return ((prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout))
  }

  return (prevProps.projects === nextProps.projects)
})

ProjectsSection.propTypes = {
  fetchProjects: PropTypes.func,
  meta: PropTypes.object,
  projects: PropTypes.array,
  sectionId: PropTypes.number,
  theme: PropTypes.object,
  updateQuery: PropTypes.func,
  isInsideAdminView: PropTypes.bool,
  hub: PropTypes.object,
  dataTestId: PropTypes.string.isRequired
}

export default ProjectsSection
