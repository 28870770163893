import React, { memo } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const CarouselSection = memo(({ theme, sectionId, dataTestId }) => {
  const CarouselLayout = dynamic(() => import(/* webpackChunkName: 'CarouselSectionLayout' */ `../../../../../containers/hub-page/views/sections/carousel/layouts/${theme.layout}`))

  return (
    <CarouselLayout
      sectionId={sectionId}
      data-testid={dataTestId}
    />
  )
}, (prevProps, nextProps) => {
  return (prevProps.theme && prevProps.theme.layout) === (nextProps.theme && nextProps.theme.layout)
})

CarouselSection.propTypes = {
  theme: PropTypes.object,
  sectionId: PropTypes.number,
  dataTestId: PropTypes.string.isRequired
}

export default CarouselSection
